import React from 'react';
import { Container } from 'react-bootstrap';

class Payment extends React.Component {
    render() {
        return (
            <Container style={{ marginTop: '25px', textAlign: 'center' }}>
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input type="hidden" name="hosted_button_id" value="9MTF2PDLLNHWY" />
                    <input type="hidden" name="currency_code" value="USD" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
                </form>
            </Container>
        );
    }
}

export default Payment;
